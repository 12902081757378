@font-face {
  font-family: "Joystix";
  src: url("fonts/joystix\ monospace.ttf") format("truetype");
}

body {
  margin: 0;
}

a:link {
  text-decoration: none;
}

a:visited {
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

a:active {
  text-decoration: none;
}

img {
  height: 100%;
  width: 100%;
  object-fit: contain;
}
